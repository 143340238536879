.history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-bottom: 24px;
}
.history-container
  > div.items-container
  > div
  > div
  > div
  > div
  > div.slick-slide
  > div {
  display: flex;
  justify-content: center;
}
.history-container {
  margin-top: 54px;
  width: 100%;
  max-height: 30%;
  position: absolute;
  bottom: 10px;
}
.history-container .title {
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 6px;
}

.item-wrapper {
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.item {
  font-size: 10px;
  font-weight: 500;
  background-color: #6ba021a4;
  padding: 4px 12px;
  color: #fff;
  border-radius: 31px;
  max-width: max-content;
  min-width: max-content;
  margin: 6px 0px;
  transition: linear 0.3s;
  transition-delay: 0.5s;
  opacity: 0.6;
}
.history-container .items-container .item_highlight {
  font-size: 14px;
  font-weight: 600;
  background-color: #6ca021;
  padding: 6px 12px;
  color: #fff;
  margin: 0px;
  border-radius: 31px;
  min-width: max-content;
  transition: linear 0.3s;
  transition-delay: 0.5s;
}
